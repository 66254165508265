// This is just an example,
// so you can safely delete all default props below

export default {
  failed: "Action failed",
  success: "Action was successful",
  index: {
    index_title: "Warehouse Management",
    webtitle: "WMS-ASG--Warehouse Management System",
    home: "Home",
    title: "WMS-ASG",
    title_tip: "WMS-ASG Home",
    hide_menu: "Hide Menu",
    api: "API DOCS",
    translate: "Choose Language",
    unread: "Unread Message",
    login: "Login",
    register: "Register",
    login_tip: "Enter Your OPENID & Login Name",
    register_tip: "Register An Admin",
    logout: "Logout",
    user_login: "User Login",
    admin_login: "Admin Login",
    return_to_login: "Return To Login Page",
    user_center: "User Center",
    change_user: "Change User",
    view_my_openid: "View My OPENID",
    your_openid: "Your OPENID",
    contact_list: "Recent Contact",
    chat_more: "Load More",
    chat_no_more: "No More Message",
    chat_send: "Send",
    previous: "Previous",
    next: "Next",
    admin_name: "Admin",
    password: "Password",
    confirm_password: "Confirm Password",
    staff_name: "User Name",
    cancel: "Cancel",
    close: "Close",
    submit: "Submit",
    download: "Download",
    updatetitle: "Update Ready",
    updatedesc: "Version Can Update Now",
    update: "Update Now",
    chart: " Chart",
    current_user: "Current User",
  },
  menuItem: {
    dashboard: "Dashboard",
    inbound: "Inbound",
    outbound: "Outbound",
    stock: "Inventory",
    finance: "Finance",
    goods: "GoodsList",
    baseinfo: "Base Info",
    warehouse: "Warehouse",
    staff: "Staff",
    driver: "Driver",
    customerdn: "Customer DN",
    supplierasn: "Supplieer ASN",
    uploadcenter: "Upload Center",
    downloadcenter: "Download Center",
    sync_acumatica: "Sync Acumatica",
  },
  contact: "Contact",
  sendmessage: "Send A Message",
  send: "Send",
  nomoremessage: "No More Message",
  loadmore: "Load More",
  new: "new",
  newtip: "New A Data",
  refresh: "Refresh",
  refreshtip: "Refresh All Data",
  edit: "Edit This Data",
  confirmedit: "Confirm Edit Data",
  canceledit: "Cancel Edit Data",
  delete: "Delete This Data",
  deletetip: "This is an irreversible process.",
  confirmdelete: "Confirm Delete Data",
  canceldelete: "Cancel Delete Data",
  download: "Download",
  downloadtip: "Download All Data",
  frombin: "From Bin",
  movetobin: "Move to Bin",
  putaway: "PutAway",
  confirmunsorted: "Confirm Unsorted",
  cyclecount: "Cycle Count",
  cyclecountrecorder: "Count Recorder",
  search: "Search Word",
  creater: "Creater",
  createtime: "Cteate Time",
  updatetime: "Update Time",
  action: "Action",
  previous: "Previous",
  next: "Next",
  no_data: "No More Data",
  submit: "Submit",
  cancel: "Cancel",
  estimate: "Estimate Freight",
  downloadasnlist: "Download List",
  downloadasndetail: "Download Detail",
  downloadasnlisttip: "Download All ASN List",
  downloadasndetailtip: "Download All ASN Detail",
  printthisasn: "Print this ASN",
  confirmdelivery: "Confirm Delivery",
  finishloading: "Finish Loading",
  confirmsorted: "Confirm Sorted",
  downloaddnlist: "Download List",
  downloaddndetail: "Download Detail",
  downloaddnlisttip: "Download All DN List",
  downloaddndetailtip: "Download All DN Detail",
  release: "Order Release",
  releaseallorder: "Release All Order",
  releaseorder: "Release Order",
  print: "Print Picking List",
  printthisdn: "Print this DN",
  confirmorder: "Confirm Order",
  confirmpicked: "Confirm Picked",
  dispatch: "Dispatch & Shipping",
  deletebackorder: "Delete Back Order",
  confirminventoryresults: "Confirm Inventory Results",
  syncasnacumatica: "Sync Asn",
  syncasnlisttip: 'Sync data acumatica to asn',
  syncdnacumatica: "Sync Dn",
  syncdnlisttip: 'Sync data acumatica to dn',
  synccustomeracumatica: 'Sync Customer',
  synccustomertip: 'Sync data acumatica to customer',
  syncsupplieracumatica: 'Sync Supplier',
  syncsuppliertip: 'Sync data acumatica to supplier',
  syncgoodsacumatica: 'Sync Goods',
  syncgoodstip: 'Sync data acumatica to goods',
  syncwarehouseacumatica: 'Sync Warehouse',
  syncwarehousetip: 'Sync data acumatica to warehouse master',
  baseinfo: {
    company_info: "Company Info",
    supplier: "Supplier",
    goods: 'goods list',
    customer: "Customer",
    warehouse: "Master Warehouse",
    view_company: {
      company_name: "Company Name",
      company_city: "Company City",
      company_address: "Company Address",
      company_contact: "Company Contact",
      company_manager: "Company Manager",
      error1: "Please Enter The Company Name",
      error2: "Please Enter The Company City",
      error3: "Please Enter The Company Address",
      error4: "Please Enter The Company Contact",
      error5: "Please Enter The Company Manager",
    },
    view_supplier: {
      b_account_id: 'BAccount ID',
      supplier_id: "Supplier ID",
      supplier_name: "Supplier Name",
      inventory_ref_nbr: "Inventory ref Number",
      is_hold: "Hold",
      status_acumatica: "Status acumatica",
      reference_number: "Reference Number",
      delivery_noted: "Delivery Note",
      supplier_city: "Supplier City",
      supplier_address: "Supplier Address",
      supplier_contact: "Supplier Contact",
      supplier_manager: "Supplier Manager",
      supplier_level: "Supplier Level",
      error1: "Please Enter the Supplier Name",
      error2: "Please Enter the Supplier City",
      error3: "Please Enter the Supplier Address",
      error4: "Please Enter the Supplier Contact",
      error5: "Please Enter the Supplier Manager",
      error6: "Please Enter the Supplier Level",
      error7: "Please Enter the Reference Number",
      error8: "Invalid input. Only a-z, A-Z, 0-9 and, '-' are allowed.",
      error9: "Please Enter the ASN type",
      plus: "+",
      error10: "Please Enter the Supplier ID",
      error11: 'Please Enter this Filed',
      note: 'Note',
      currencyID: 'currency ID',
      CurrencyRateType: 'currency Rate Type',
      LocationName: 'Location Name',
      PaymentBy: 'Payment By',
      PaymentMethod: 'Payment Method'
    },
    view_customer: {
      b_account_id: 'BAccount ID',
      customer_class: 'Customer Class',
      customer_id: 'Customer ID',
      customer_status: 'Customer Status',
      fob_point: 'FOB Point',
      description: 'Description',
      shipping_zone_id: 'Shipping Zone',
      shipping_branch: 'Shipping Branch',
      warehouse_id: 'Warehouse ID',
      customer_name: "Customer Name",
      reference_number: "Reference Number",
      delivery_noted: "Delivery Note",
      customer_city: "Customer City",
      customer_address: "Customer Address",
      customer_contact: "Customer Contact",
      customer_manager: "Customer Manager",
      customer_level: "Customer Level",
      error1: "Please Enter the Customer Name",
      error2: "Please Enter the Customer City",
      error3: "Please Enter the Customer Address",
      error4: "Please Enter the Customer Contact",
      error5: "Please Enter the Customer Manager",
      error6: "Please Enter the Customer Level",
      error7: "Please Enter the Reference Number",
      error8: "Invalid input. Only a-z, A-Z, 0-9 and, '-' are allowed.",
      error9: 'Please Enter the DN Type',
      error10: 'Please Enter this Field',
      error11: 'Please Enter the Customer ID',
      plus: "+",
    },
    view_warehouse_acumatica: {
      id_acumatica_wh: "acumatica ID",
      warehouse_id: "warehouse ID",
      description: "Description",
      note: 'note',
      isActive: "Active",
      last_modified_datetime: "Last Modified Datetime",
      receiving_location_id: "Receiving Location"
    }
  },
  dashboards: {
    outbound_statements: "Outbound",
    inbound_statements: "Inbound",
    inbound_and_outbound_statements: "Inbound And Outbound",
    total_sales: "Total Sales",
    sales_volume_ranking: "Sales Volume Ranking",
    sales_volumes_ranking: "Sales Volumes Ranking",
    total_receipts: "Total Receipts",
    receiving_quantity_ranking: "Receiving Quantity Ranking",
    Receiving_amount_ranking: "Receiving Amount Ranking",
    view_tradelist: {
      mode_code: "Mode Of Doing Business",
      bin_name: "Location Name",
      goods_code: "Goods Code",
      goods_qty: "Quantity On Hand",
      creater: "Creater",
      update_time: "Update Time",
      create_time: "Create Time",
      inbound: "Inbound",
      outbound: "Outbound",
    },
  },
  finance: {
    capital: "Capital",
    freight: "Freight",
    view_capital: {
      capital_name: "Cpaital Name",
      capital_qty: "Capital Qty",
      capital_cost: "Capital Cost",
      error1: "Please Enter the Capital Name",
      error2: "Capital Qty width must greater than 0",
      error3: "Capital Cost depth must greater than 0",
    },
    view_freight: {
      transportation_supplier: "Transportation Supplier",
      send_city: "Send City",
      receiver_city: "Receiver City",
      weight_fee: "Weight Fee",
      volume_fee: "Volume Fee",
      min_payment: "Min Payment",
      error1: "Please Enter the Transportation Supplier",
      error2: "Please Enter the Send City",
      error3: "Please Enter the Receiver City",
      error4: "Weight Fee must greater than 0",
      error5: "Volume Fee must greater than 0",
      error6: "Min Payment must greater than 0",
    },
  },
  driver: {
    driver: "Driver",
    dispatchlist: "Dispatch List",
    error1: "Please Enter the Driver Name",
    error2: "Please Enter the License Plate",
    error3: "Please Enter The Contact",
    view_driver: {
      driver_name: "Driver Name",
      license_plate: "License Plate",
      contact: "Contact",
    },
    view_dispatch: {
      driver_name: "Driver Name",
      dn_code: "DN Code",
      contact: "Contact",
    },
  },
  upload_center: {
    initializeupload: "Initialize upload",
    uploadfiles: "Upload",
    upload: "Upload",
    uploadcustomerfile: "Upload Customerfile",
    uploadgoodslistfile: "Upload GoodslistFile",
    uploadsupplierfile: "Upload SupplierFile",
    downloadgoodstemplate: "Goods Example",
    downloadcustomertemplate: "Customer Example",
    downloadsuppliertemplate: "Supplier Example",
    addupload: "Add Upload",
  },
  download_center: {
    createTime: "Create Time",
    reset: "Reset",
    start: "Start",
    end: "End",
  },
  community_mall: {
    communitymall: "Community Mall",
  },
  goods: {
    goods_list: "Goods List",
    unit: "Unit",
    class: "Class",
    color: "Color",
    brand: "Brand",
    shape: "Shape",
    specs: "Specs",
    origin: "Origin",
    view_goodslist: {
      note_id: "Note ID",
      goods_code: "Goods Code",
      goods_qty: "Total Qty",
      goods_desc: "Goods Desc",
      cross_references: "Cross References",
      item_type: "Item Type",
      posting_class: "Posting Class",
      price_class: "Price Class",
      purchase_uom: "Purchase UOM",

      goods_name: "Goods Name",
      goods_supplier: "Goods Supplier",
      goods_weight: "Goods Weight(Unit:g)",
      goods_w: "Goods Width(Unit:mm)",
      goods_d: "Goods Depth(Unit:mm)",
      goods_h: "Goods Height(Unit:mm)",
      unit_volume: "Unit Volume",
      goods_unit: "Goods Unit",
      goods_class: "Goods Class",
      goods_brand: "Goods Brand",
      goods_color: "Goods Color",
      goods_shape: "Goods Shape",
      goods_specs: "Goods Specs",
      goods_origin: "Goods Origin",
      goods_cost: "Goods Cost",
      goods_price: "Goods Price",
      print_goods_label: "Print Goods Label",
      error1: "Please Enter the Goods Code",
      error2: "Please Enter the Goods Description",
      error3: "Please Enter the Supplier",
      error4: "Goods Weight Must Greater Than 0",
      error5: "Goods Width Must Greater Than 0",
      error6: "Goods Depth Must Greater Than 0",
      error7: "Goods Height Must Greater Than 0",
      error8: "Please Enter the Goods Cost",
      error9: "Please Enter the Goods Price",
    },
    view_unit: {
      goods_unit: "Goods Unit",
      error1: "Please Enter Goods Unit",
    },
    view_class: {
      goods_class: "Goods Class",
      error1: "Please Enter Goods Class",
    },
    view_color: {
      goods_color: "Goods Color",
      error1: "Please Enter Goods Color",
    },
    view_brand: {
      goods_brand: "Goods Brand",
      error1: "Please Enter Goods Brand",
    },
    view_shape: {
      goods_shape: "Goods Shape",
      error1: "Please Enter Goods Shape",
    },
    view_specs: {
      goods_specs: "Goods Specs",
      error1: "Please Enter Goods Specs",
    },
    view_origin: {
      goods_origin: "Goods Origin",
      error1: "Please Enter Goods Origin",
    },
  },
  inbound: {
    asn: "ASN",
    predeliverystock: "Pre Delivery",
    preloadstock: "Pre Load",
    presortstock: "Sorting",
    sortstock: "Sorted",
    shortage: "Shortage",
    unsortstock: "Unsorted",
    more: "More QTY",
    asnfinish: "Receiving List",
    asndone: "Finish Receiving",
    view_sortstock: {
      error1: "Please Enter The Quantity Must Be Greater Than 0",
    },
    view_asn: {
      asn_code: "ASN Code",
      asn_status: "ASN Status",
      type_asn: "ASN Type",
      goods_qty: "ASN QTY",
      goods_actual_qty: "Actual Arrive Qty",
      goods_shortage_qty: "Arrive Shortage Qty",
      goods_more_qty: "Arrive More Qty",
      goods_damage_qty: "Arrive Damage Qty",
      presortstock: "Pre Sort Qty",
      sorted_qty: "Sorted Qty",
      unsorted_qty: "Unsorted Qty",
      total_weight: "Total Weight(Unit:KG)",
      total_volume: "Total Volume(Unit:Cubic Metres)",
      reference_number: "Reference Number",
      delivery_noted: "Delivery Note",
      unsorted_note: "Unsorted Note",
      note: "Note",
    },
  },
  outbound: {
    dn: "DN",
    freshorder: "Pre Order",
    neworder: "New Order",
    backorder: "Back Order",
    pickstock: "Pre Pick",
    pickedstock: "Picked",
    pickinglist: "Picking List",
    shippedstock: "Shipping List",
    received: "Received",
    pod: "Proof Of Delivery",
    view_dn: {
      goods_code: "Goods Code",
      goods_desc: "Goods Desc",
      dn_code: "DN Code",
      dn_status: "DN Status",
      type_dn: "DN Type",
      goods_qty: "Order QTY",
      intransit_qty: "Shipping QTY",
      delivery_actual_qty: "Delivery Actual QTY",
      delivery_shortage_qty: "Delivery Shortage QTY",
      delivery_more_qty: "Delivery More QTY",
      delivery_damage_qty: "Delivery Damage QTY",
      total_weight: "Total Weight(Unit:KG)",
      total_volume: "Total Volume(Unit:Cubic Metres)",
      reference_number: "Reference Number",
      delivery_noted: "Delivery Note",
      customer: "Customer",
      fob_point: "FOB Point",
      is_hold: "Hold",
      status_acumatica: "Status Acumatica",
      type_acumatica: "Type Acumatica"
    },
  },
  staff: {
    staff: "Staff",
    check_code: "Check Code",
    view_staff: {
      staff_name: "Staff Name",
      staff_type: "Staff Type",
      error1: "Please Enter The Staff Name",
      error2: "Please Enter The Staff Type",
      lock: "lock",
      unlock: "unlock",
    },
  },
  stock: {
    stocklist: "Stock List",
    stockbinlist: "Bin List",
    emptybin: "Empty Bin",
    occupiedbin: "Occupied Bin",
    view_stocklist: {
      goods_code: "Goods Code",
      goods_desc: "Goods Desc",
      goods_name: "Goods Name",
      goods_qty: "Total Qty",
      onhand_stock: "On hand",
      can_order_stock: "Can Order",
      ordered_stock: "Ordered Stock",
      inspect_stock: "Inspect",
      hold_stock: "Holding",
      damage_stock: "Damage",
      asn_stock: "ASN Stock",
      dn_stock: "DN Stock",
      pre_load_stock: "Pre Load",
      pre_sort_stock: "Pre Sort",
      sorted_stock: "Sorted Stock",
      unsorted_stock: "Unsorted Stock",
      pick_stock: "Pick Stock",
      picked_stock: "Picked Stock",
      back_order_stock: "Back Order",
      on_hand_inventory: "On-Hand Inventory",
      history_inventory: "History Inventory",
      physical_inventory: "Physical Inventory",
      difference: " Difference",
      cyclecount: "Cycle Count",
      recyclecount: "Recycle",
      downloadcyclecount: "Counting table",
      cyclecountresult: "Confirm result",
      cyclecounttip: "Generate A Dynamic Cycle Count Table",
      recyclecounttip: "Generate A Recycle Count Table",
      downloadcyclecounttip: "Download Cycle Count Table",
      cyclecountresulttip: "Confirm The Cycle Count Result",
      daychoice: "Date Selection",
      daychoicetip: "Select The Cycle Count Table Corresponding To The Date",
      error1: "Count Quantity Must Be Greater Than 0",
      dateerror: "Incorrect Date Selected",
    },
  },
  warehouse: {
    warehouse: "Warehouse",
    binset: "Bin Set",
    binsize: "Bin Size",
    property: "Bin Property",
    printbin: "Print Bin Label",
    view_warehouseset: {
      error1: "Please Enter the Warehouse Name",
      error2: "Please Enter The Warehouse City",
      error3: "Please Enter The Warehouse Address",
      error4: "Please Enter the Warehouse Contact",
      error5: "Please Enter The Warehouse Manager",
      error6: "Please Enter The Warehouse Code"
    },
    view_warehouse: {
      warehouse_name: "Warehouse Name",
      warehouse_code: "Warehouse Code",
      warehouse_city: "Warehouse City",
      warehouse_address: "Warehouse Address",
      warehouse_contact: "Warehouse Contact",
      warehouse_manager: "Warehouse Manager",
    },
    view_binset: {
      bin_name: "Bin Name",
      bin_size: "Bin Size",
      bin_property: "Bin Property",
      empty_label: "Empty Label",
      error1: "Please Enter the Bin Name",
      error2: "Please Enter the Bin Size",
      error3: "Please Enter the Bin Property",
    },
    view_binsize: {
      bin_size: "Bin Size",
      bin_size_w: "Bin Size Wide(Unit:mm)",
      bin_size_d: "Bin Size Depth(Unit:mm)",
      bin_size_h: "Bin Size Height(Unit:mm)",
      error1: "Please Enter the Bin_size",
      error2: "Bin Size width must greater than 0",
      error3: "Bin Size depth must greater than 0",
      error4: "Bin Size height must greater than 0",
    },
    view_property: {
      bin_property: "Bin Property",
    },
  },
  scan: {
    scan: "SCAN",
    scan_asn: "ASN Check",
    scan_dn: "DN Check",
    scan_sorting: "Sorting",
    scan_uptobin: "Up To Bin",
    scan_picking: "Picking",
    scan_shipping: "Dispatch",
    scan_movetobin: "Bin Move",
    scan_inventory: "Count",
    scan_goodsquery: "Goods",
    scan_locationquery: "Bin",
    scan_goods_code: "Goods Code",
    scan_bin_name: "Location Name",
    scan_goods_label: "Goods Label",
    scan_goods_label_error: "Product Label Does Not Exist",
    view_binmove: {
      old_bin_name: "Old Location Name",
      new_bin_name: "New Location Name",
      qty: "Shifting Quantity",
      qty_error: "Quantity Shall Not Exceed Existing Quantity",
    },
    view_upToBin: {
      goods_actual_qty: "Actual Arrive Quantity",
      scan_qty: "Scanned Quantity",
      scan_qty_error: "Sanning Quantity Shall Not Exceed Arrived Quantity",
    },
    view_packing: {
      order_qty: "Order Quantity",
      packing_qty: "Sorting Quantity",
      packing_qty_error: "Sorting Quantity Shall Not Exceed Order Quantity",
    },
    view_shipping: {
      shipping_code: "Shipment Tracking Number",
      driver_info: "Driver Information",
      license_plate_number: "License Plate Number",
      name: "Name",
      contact_info: "Contact Information",
    },
  },
  notice: {
    valerror: "Please enter the correct value",
    unknow_error: "Unknow Error",
    network_error: "Network Abnormal",
    nullerror: "",
    cyclecounterror: "No Data",
    userererror: "Username Already Exists",
    capitalerror: "Fixed Asset Name Already Exists",
    valuenullerror: "Please Fill In The Complete Data",
    loginerror: "Please Log In First",
    goodserror: {
      goods_listerror: "The Product Code Already Exists",
      goods_uniterror: "Commodity Unit Already Exists",
      goods_classerror: "The Product Category Already Exists",
      goods_colorerror: "Product Color Already Exists",
      goods_branderror: "The Product Brand Already Exists",
      goods_shapeerror: "The Product Shape Already Exists",
      goods_specserror: "Product Specifications Already Exists",
      goods_originerror: "The Origin Of The Goods Already Exists",
    },
    baseinfoerror: {
      companyerror: "Company Name Already Exists",
      customererror: "Customer Name Already Exists",
      customeriderror: 'Customer ID Already Exists',
      suppliererror: "Supplier Name Already Exists",
      supplieriderror: "Supplier ID Already Exists"
    },
    warehouseerror: {
      binseterror: "The Location Name Already Exists",
      binsizeerror: "Location Size Already Exists",
    },
    400: "Bad request (400)",
    401: "Authorization not obtained (401)",
    403: "Access denied (403)",
    404: "Resource does not exist (404)",
    405: "The function is disabled (405)",
    408: "Request timed out (408)",
    409: "Data conflict (409)",
    410: "Data has been deleted (410)",
    500: "Server error (500)",
    501: "Service not implemented (501)",
    502: "Network error (502)",
    503: "Service unavailable (503)",
    504: "Network timeout (504)",
    505: "HTTP version is not supported (505)",
  },
};
