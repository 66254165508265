// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  index: {
    index_title: 'Plataforma de gestión de almacenes',
    webtitle: 'WMS-ASG: sistema de gestión de almacenes de código abierto',
    home: 'página delantera',
    title: 'WMS-ASG',
    title_tip: 'Página de inicio de WMS-ASG',
    hide_menu: 'Cerrar menú',
    api: 'Documentación API',
    translate: 'cambiar idioma',
    unread: 'Mensajes no leídos',
    login: 'acceso',
    register: 'Registrarse',
    login_tip: 'Ingrese su OPENID y nombre de usuario',
    register_tip: 'Registrarse como administrador',
    logout: 'Cerrar sesión',
    user_login: 'Inicio de sesión de usuario',
    admin_login: 'Inicio de sesión de administrador',
    return_to_login: 'volver a iniciar sesión',
    user_center: 'Centro de Usuario',
    change_user: 'Cambiar usuario',
    view_my_openid: 'Ver mi OPENID',
    your_openid: 'tu OPENID',
    contact_list: 'Contactos recientes',
    chat_more: 'Más noticias históricas',
    chat_no_more: 'No más noticias',
    chat_send: 'enviar',
    previous: 'Pagina anterior',
    next: 'Siguiente página',
    admin_name: 'administrador',
    password: 'contraseña',
    confirm_password: 'Confirmar contraseña',
    staff_name: 'nombre de usuario',
    cancel: 'Cancelar',
    close: 'cierre',
    submit: 'entregar',
    download: 'descargar',
    updatetitle: 'Actualización lista',
    updatedesc: 'Versión lista para actualizar',
    update: 'Actualizar ahora',
    chart: 'Reporte',
    current_user: 'Usuario actual'
  },
  menuItem: {
    dashboard: 'Centro de informes',
    inbound: 'Gestión de recibos',
    outbound: 'Gestión de Envíos',
    stock: 'La gestión del inventario',
    finance: 'Centro financiero',
    goods: 'Gestión de productos básicos',
    baseinfo: 'ajustes básicos',
    warehouse: 'Configuración de almacén',
    staff: 'Gestión de usuarios',
    driver: 'Gestión de conductores',
    customerdn: 'Pedido del cliente',
    supplierasn: 'Pedido de proveedor',
    uploadcenter: 'Centro de carga',
    downloadcenter: 'Centro de descargas'
  },
  contact: 'Comunícate de inmediato',
  sendmessage: 'Envíale un mensaje',
  send: 'enviar',
  nomoremessage: 'No más noticias',
  loadmore: 'carga más',
  new: 'Agregar',
  newtip: 'Agregar un nuevo dato',
  refresh: 'actualizar',
  refreshtip: 'Actualizar todos los datos',
  edit: 'Editar estos datos',
  confirmedit: 'Confirmar editar datos',
  canceledit: 'Cancelar editar datos',
  delete: 'Borrar estos datos',
  deletetip: "Esta operación es irreversible. '",
  confirmdelete: 'Confirmar borrar datos',
  canceldelete: 'Recuperar datos',
  download: 'descargar',
  downloadtip: 'Descargar todos los datos',
  frombin: 'Desde la ubicación de almacenamiento',
  movetobin: 'Transferir',
  putaway: 'en la repisa',
  cyclecount: 'Inventario dinámico',
  cyclecountrecorder: 'Registro de inventario',
  search: 'Búsqueda por palabra clave',
  creater: 'fundador',
  createtime: 'crear tiempo',
  updatetime: 'Tiempo de actualizacion',
  action: 'funcionar',
  previous: 'Pagina anterior',
  next: 'Siguiente página',
  no_data: 'No más datos',
  submit: 'confirmar',
  cancel: 'Cancelar',
  estimate: 'Envío estimado',
  downloadasnlist: 'Descargar lista',
  downloadasndetail: 'Descargar detalles',
  downloadasnlisttip: 'Descargar listado de avisos de llegada',
  downloadasndetailtip: 'Descargar los detalles del aviso de llegada',
  printthisasn: 'Consulta el aviso de llegada',
  confirmdelivery: 'Ha llegado la confirmación',
  finishloading: 'Confirmar descarga completada',
  confirmsorted: 'Confirmar clasificación completa',
  downloaddnlist: 'Descargar lista',
  downloaddndetail: 'Descargar detalles',
  downloaddnlisttip: 'Descargar lista de facturas',
  downloaddndetailtip: 'Descargar detalles de factura',
  release: 'orden de coincidencia',
  releaseallorder: 'Todos generan lista de selección',
  releaseorder: 'Generar lista de selección',
  print: 'Imprimir lista de selección',
  printthisdn: 'Ver e imprimir facturas',
  confirmorder: 'Confirmar pedido',
  confirmpicked: 'Confirmar recolección completa',
  dispatch: 'Carga y entrega',
  deletebackorder: 'Eliminar pedido pendiente',
  confirminventoryresults: 'Confirmar el resultado del conteo',
  baseinfo: {
    company_info: 'información de la empresa',
    supplier: 'proveedor',
    customer: 'cliente',
    view_company: {
      company_name: 'nombre de empresa',
      company_city: 'Ubicación ciudad',
      company_address: 'habla a',
      company_contact: 'Información del contacto',
      company_manager: 'principal',
      error1: 'Por favor ingrese el nombre de la empresa',
      error2: 'Por favor ingrese la ciudad donde se encuentra la empresa ',
      error3: 'Por favor, introduzca la dirección de la empresa',
      error4: 'Por favor, introduzca el contacto de la empresa',
      error5: 'Por favor ingrese el responsable de la empresa'
    },
    view_supplier: {
      supplier_name: 'Nombre del proveedor',
      supplier_city: 'Ubicación ciudad',
      supplier_address: 'habla a',
      supplier_contact: 'Información del contacto',
      supplier_manager: 'principal',
      supplier_level: 'Nivel de proveedor',
      error1: 'Por favor ingrese el nombre del proveedor',
      error2: 'Por favor ingrese la ciudad donde se encuentra el proveedor',
      error3: 'Por favor ingrese la dirección del proveedor',
      error4: 'Por favor ingrese la información de contacto',
      error5: 'Por favor ingrese el responsable del proveedor',
      error6: 'Por favor ingrese el nivel de proveedor'
    },
    view_customer: {
      customer_name: 'nombre del cliente',
      customer_city: 'Ubicación ciudad',
      customer_address: 'habla a',
      customer_contact: 'Información del contacto',
      customer_manager: 'principal',
      customer_level: 'Nivel de cliente',
      error1: 'Por favor ingrese el nombre del cliente',
      error2: 'Por favor ingrese la ciudad donde se encuentra el cliente',
      error3: 'Por favor ingrese la dirección del cliente',
      error4: 'Por favor ingrese un contacto',
      error5: 'Por favor ingrese administrador de cuenta',
      error6: 'Por favor ingrese el nivel de cliente'
    }
  },
  dashboards: {
    outbound_statements: 'Informe de salida',
    inbound_statements: 'Informe de recepción',
    inbound_and_outbound_statements: 'Informe de recepción y entrega',
    total_sales: 'Ventas totales',
    category_sales_ranking: 'Clasificación de ventas de categoría',
    sales_volume_ranking: 'Rango de ventas',
    sales_volumes_ranking: 'Número de ranking de ventas',
    total_receipts: 'Recibo Total',
    category_receipt_ranking: 'Clasificación de recepción de categoría',
    receiving_quantity_ranking: 'Rango de cantidad de recibo',
    Receiving_amount_ranking: 'Clasificación de recibos',
    view_tradelist: {
      mode_code: 'medios de transacción',
      bin_name: 'Nombre del lugar',
      goods_code: 'Código de mercancía',
      goods_qty: 'Cantidad en mano',
      creater: 'fundador',
      update_time: 'Tiempo de actualizacion',
      create_time: 'crear tiempo',
      inbound: 'Recibo',
      outbound: 'Barco'
    }
  },
  finance: {
    capital: 'Activos fijos',
    freight: 'Gestión de mercancías',
    view_capital: {
      capital_name: 'Nombre de activo fijo',
      capital_qty: 'cantidad',
      capital_cost: 'Monto',
      error1: 'Por favor ingrese el nombre del activo',
      error2: 'El número de activos debe ser mayor que 0',
      error3: 'La cantidad del activo debe ser mayor que 0'
    },
    view_freight: {
      transportation_supplier: 'Transportador',
      send_city: 'Ciudad de origen',
      receiver_city: 'Ciudad de Llegada',
      weight_fee: 'Envío por kilo',
      volume_fee: 'Envío de un solo metro cúbico',
      min_payment: 'Envío mínimo',
      error1: 'Por favor ingrese un transportista',
      error2: 'Por favor ingrese la ciudad de envío',
      error3: 'Por favor ingrese la ciudad receptora',
      error4: 'El costo de envío por kilogramo debe ser mayor a 0',
      error5: 'El costo de envío de un solo metro cúbico debe ser mayor que 0',
      error6: 'El envío mínimo debe ser mayor que 0'
    }
  },
  driver: {
    driver: 'Gestión de conductores',
    dispatchlist: 'Registro de recogida',
    error1: 'Por favor ingrese el nombre del conductor',
    error2: 'Por favor, introduzca el número de matrícula',
    error3: 'Por favor ingrese un contacto',
    view_driver: {
      driver_name: 'nombre del conductor',
      license_plate: 'número de placa',
      contact: 'Información del contacto'
    },
    view_dispatch: {
      driver_name: 'nombre del conductor',
      dn_code: 'Número de pedido de recogida',
      contact: 'Información del contacto'
    }
  },
  upload_center: {
    initializeupload: 'Inicializar carga',
    uploadfiles: 'subir archivos',
    upload: 'subir',
    uploadcustomerfile: 'Subir lista de clientes',
    uploadgoodslistfile: 'Cargar lista de formularios de productos',
    uploadsupplierfile: 'Subir lista de proveedores',
    downloadgoodstemplate: 'Descargar plantilla de producto',
    downloadcustomertemplate: 'Descargar plantilla de cliente',
    downloadsuppliertemplate: 'Descargar plantilla de proveedor',
    addupload: 'Agregar carga'
  },
  download_center: {
    createTime: 'crear tiempo',
    reset: 'Reiniciar',
    start: 'Comienzo',
    end: 'Terminar'
  },
  community_mall: {
    communitymall: 'Centro comercial comunitario'
  },
  goods: {
    goods_list: 'Lista de productos',
    unit: 'Unidad de producto',
    class: 'Categoría de artículo',
    color: 'color del producto',
    brand: 'producto de marca',
    shape: 'Forma del producto',
    specs: 'Especificaciones del producto',
    origin: 'Origen del producto',
    view_goodslist: {
      goods_code: 'Código de mercancía',
      goods_desc: 'Descripción del Producto',
      goods_name: 'nombre del producto',
      goods_supplier: 'proveedor',
      goods_weight: 'Peso de la mercancía (unidad: gramos)',
      goods_w: 'Longitud del artículo (unidad: mm)',
      goods_d: 'Ancho del producto (unidad: mm)',
      goods_h: 'Altura de la mercancía (unidad: mm)',
      unit_volume: 'Volumen unitario mínimo',
      goods_unit: 'Unidad de producto',
      goods_class: 'Categoría de artículo',
      goods_brand: 'producto de marca',
      goods_color: 'color del producto',
      goods_shape: 'Forma del producto',
      goods_specs: 'Especificaciones del producto',
      goods_origin: 'Origen del producto',
      goods_cost: 'costo de la materia prima',
      goods_price: 'Precio de la materia prima',
      print_goods_label: 'Imprimir etiqueta del producto',
      error1: 'Por favor ingrese el código del producto',
      error2: 'Por favor ingrese la descripción del producto',
      error3: 'Por favor ingrese el proveedor del producto',
      error4: 'El peso del artículo debe ser mayor que 0',
      error5: 'La longitud del elemento debe ser mayor que 0',
      error6: 'El ancho del producto debe ser mayor que 0',
      error7: 'La altura del producto debe ser mayor que 0',
      error8: 'Ingrese el costo de los bienes',
      error9: 'Por favor ingrese el precio del producto'
    },
    view_unit: {
      goods_unit: 'Unidad de producto',
      error1: 'Por favor ingrese la unidad de producto'
    },
    view_class: {
      goods_class: 'Categoría de artículo',
      error1: 'Por favor ingrese la categoría del producto'
    },
    view_color: {
      goods_color: 'color del producto',
      error1: 'Por favor ingrese el color del producto'
    },
    view_brand: {
      goods_brand: 'producto de marca',
      error1: 'Por favor ingrese la marca del producto'
    },
    view_shape: {
      goods_shape: 'Forma del producto',
      error1: 'Por favor ingrese la forma del producto'
    },
    view_specs: {
      goods_specs: 'Especificaciones del producto',
      error1: 'Por favor ingrese las especificaciones del producto'
    },
    view_origin: {
      goods_origin: 'Origen del producto',
      error1: 'Por favor ingrese el origen del producto'
    }
  },
  inbound: {
    asn: 'Aviso de llegada',
    predeliverystock: 'llegar',
    preloadstock: 'Para ser descargado',
    presortstock: 'para ser ordenado',
    sortstock: 'ordenado',
    shortage: 'Escaso de bienes entrantes',
    more: 'Más llegadas',
    asnfinish: 'Detalles del recibo',
    asndone: 'Recibo completado',
    view_sortstock: {
      error1: 'Por favor ingrese la cantidad, la cantidad debe ser mayor a 0'
    },
    view_asn: {
      asn_code: 'Número de aviso de llegada',
      asn_status: 'Estado de notificación de llegada',
      goods_qty: 'Número de avisos de llegada',
      goods_actual_qty: 'Cantidad de llegada real',
      goods_shortage_qty: 'Llegó en cantidad corta',
      goods_more_qty: 'Gran cantidad de llegada',
      goods_damage_qty: 'Número de llegadas dañadas',
      presortstock: 'Número a ordenar',
      sorted_qty: 'Cantidad en inventario',
      total_weight: 'Peso total (unidad: kg)',
      total_volume: 'Volumen total (unidad: metro cúbico)'
    }
  },
  outbound: {
    dn: 'Factura',
    freshorder: 'Pre frectura',
    neworder: 'Nueva Factura',
    backorder: 'Pedidos pendientes',
    pickstock: 'ser seleccionado',
    pickedstock: 'Escogido',
    pickinglist: 'Seleccionar detalles de la lista',
    shippedstock: 'Enviado',
    received: 'Ha sido recibido',
    pod: 'Firma el recibo',
    view_dn: {
      dn_code: 'numero de envío',
      dn_status: 'Estado de la factura',
      goods_qty: 'cantidad de pedido',
      intransit_qty: 'Cantidad enviada',
      delivery_actual_qty: 'Cantidad de llegada real',
      delivery_shortage_qty: 'Llegada corta',
      delivery_more_qty: 'Más llegadas',
      delivery_damage_qty: 'Llegó dañado',
      total_weight: 'Peso total (unidad: kg)',
      total_volume: 'Volumen total (unidad: metro cúbico)',
      customer: 'cliente'
    }
  },
  staff: {
    staff: 'Lista de empleados',
    check_code: 'Código de verificación',
    view_staff: {
      staff_name: 'nombre de empleado',
      staff_type: 'tipo de empleado',
      error1: 'Por favor ingrese el nombre del empleado',
      error2: 'Por favor ingrese el tipo de empleado',
      lock: 'cierre',
      unlock: 'desbloquear'
    }
  },
  stock: {
    stocklist: 'lista común',
    stockbinlist: 'Lista de ubicaciones',
    emptybin: 'Ubicación vacía',
    occupiedbin: 'Ubicación en stock',
    view_stocklist: {
      goods_code: 'Código de mercancía',
      goods_desc: 'Descripción del Producto',
      goods_qty: 'La cantidad total',
      onhand_stock: 'Cantidad en mano',
      can_order_stock: 'Cantidad que se puede colocar',
      ordered_stock: 'Número de pedidos realizados',
      inspect_stock: 'Cantidad de inspección de calidad',
      hold_stock: 'Cantidad bloqueada',
      damage_stock: 'Cantidad de descanso',
      asn_stock: 'Número de avisos de llegada',
      dn_stock: 'Cantidad de factura',
      pre_load_stock: 'Cantidad a descargar',
      pre_sort_stock: 'Número a ordenar',
      sorted_stock: 'Número ordenado',
      pick_stock: 'Cantidad a recoger',
      picked_stock: 'Cantidad recogida',
      back_order_stock: 'Cantidad de bienes adeudados',
      on_hand_inventory: 'existencias existentes',
      history_inventory: 'En stock en ese momento',
      physical_inventory: 'Contar cantidad',
      difference: 'diferencia',
      cyclecount: 'Inventario dinámico',
      recyclecount: 'Reiniciar',
      downloadcyclecount: 'Lista de inventario',
      cyclecountresult: 'verificar resultados',
      cyclecounttip: 'Generar tabla de inventario dinámico',
      recyclecounttip: 'Generar lista de verificación de reabastecimiento',
      downloadcyclecounttip: 'Descargar lista de verificación',
      cyclecountresulttip: 'Confirmar el resultado del conteo',
      daychoice: 'Selección de fecha',
      daychoicetip: 'Seleccione la tabla de inventario correspondiente a la fecha',
      error1: 'El número de conteos debe ser mayor que 0',
      dateerror: 'Fecha incorrecta seleccionada'
    }
  },
  warehouse: {
    warehouse: 'Configuración de almacén',
    binset: 'Configuración de ubicación de stock',
    binsize: 'Tamaño de existencias',
    property: 'Propiedad de ubicación',
    printbin: 'Imprimir etiqueta de ubicación',
    view_warehouseset: {
      error1: 'Por favor ingrese el nombre del almacén',
      error2: 'Por favor ingrese la ciudad donde se encuentra el almacén',
      error3: 'Por favor ingrese la dirección del almacén',
      error4: 'Ingrese la información de contacto del almacén',
      error5: 'Por favor ingrese el responsable del almacén'
    },
    view_warehouse: {
      warehouse_name: 'nombre del almacén',
      warehouse_city: 'Ubicación ciudad',
      warehouse_address: 'habla a',
      warehouse_contact: 'Información del contacto',
      warehouse_manager: 'principal'
    },
    view_binset: {
      bin_name: 'Nombre del lugar',
      bin_size: 'Tamaño de existencias',
      bin_property: 'Propiedad de ubicación',
      empty_label: 'ID de ubicación vacío',
      error1: 'Por favor ingrese el nombre de la ubicación',
      error2: 'Por favor ingrese el tamaño de la ubicación',
      error3: 'Por favor ingrese el atributo de ubicación'
    },
    view_binsize: {
      bin_size: 'Tamaño de existencias',
      bin_size_w: 'Longitud de stock (unidad: mm)',
      bin_size_d: 'Ancho de stock (unidad: mm)',
      bin_size_h: 'Altura de stock (unidad: mm)',
      error1: 'Por favor ingrese el tamaño de la ubicación',
      error2: 'La longitud de la ubicación de almacenamiento debe ser mayor que 0',
      error3: 'El ancho del stock debe ser mayor que 0',
      error4: 'La altura del stock debe ser mayor que 0'
    },
    view_property: {
      bin_property: 'Propiedades de ubicación de stock'
    }
  },
  scan: {
    scan: 'exploración',
    scan_asn: 'consulta ASN',
    scan_dn: 'Consulta DN',
    scan_sorting: 'clasificación',
    scan_uptobin: 'en la repisa',
    scan_picking: 'cosecha',
    scan_shipping: 'Carga y entrega',
    scan_movetobin: 'Transferir',
    scan_inventory: 'inventario',
    scan_goodsquery: 'Consulta de carga',
    scan_locationquery: 'Consulta de ubicación de existencias',
    scan_goods_code: 'Código de mercancía',
    scan_bin_name: 'Nombre del lugar',
    scan_goods_label: 'Etiqueta del producto',
    scan_goods_label_error: 'La etiqueta del artículo no existe',
    view_binmove: {
      old_bin_name: 'Nombre de ubicación original',
      new_bin_name: 'Nuevo nombre de ubicación',
      qty: 'Número de almacenes movidos',
      qty_error: 'La cantidad a mover no podrá ser superior a la existente'
    },
    view_upToBin: {
      goods_actual_qty: 'Cantidad de llegada real',
      scan_qty: 'Número de escaneados',
      scan_qty_error: 'El número de escaneos no debe ser mayor que el número de llegadas'
    },
    view_picking: {
      order_qty: 'cantidad de pedido',
      picking_qty: 'Número de selecciones',
      picking_qty_error: 'La cantidad de recogida no debe ser mayor que la cantidad del pedido'
    },
    view_shipping: {
      shipping_code: 'numero de envío',
      driver_info: 'información del conductor',
      license_plate_number: 'número de placa',
      name: 'Nombre',
      contact_info: 'Información del contacto'
    }
  },
  notice: {
    valerror: 'Por favor ingrese el valor correcto',
    unknow_error: 'error desconocido',
    network_error: 'anomalía en la red',
    nullerror: '',
    cyclecounterror: 'sin datos',
    userererror: 'Nombre de usuario ya existe',
    capitalerror: 'El nombre del activo fijo ya existe',
    valuenullerror: 'Por favor complete los datos completos',
    loginerror: 'por favor ingresa primero',
    goodserror: {
      goods_listerror: 'El código de la mercancía ya existe',
      goods_uniterror: 'La unidad de producto ya existe',
      goods_classerror: 'La categoría de artículo ya existe',
      goods_colorerror: 'El color del artículo ya existe',
      goods_branderror: 'La marca de producto ya existe',
      goods_shapeerror: 'La forma del artículo ya existe',
      goods_specserror: 'La especificación del producto ya existe',
      goods_originerror: 'El origen del producto ya existe'
    },
    baseinfoerror: {
      companyerror: 'El nombre de la empresa ya existe',
      customererror: 'El nombre del cliente ya existe',
      suppliererror: 'El nombre del proveedor ya existe'
    },
    warehouseerror: {
      binseterror: 'El nombre de la ubicación ya existe',
      binsizeerror: 'El tamaño del stock ya existe'
    },
    400: 'Solicitud incorrecta (400)',
    401: 'No autorizado (401)',
    403: 'Acceso denegado (403)',
    404: 'El recurso no existe (404)',
    405: 'Esta función está deshabilitada (405)',
    408: 'Solicitud agotada (408)',
    409: 'Conflicto de datos (409)',
    410: 'Datos eliminados (410)',
    500: 'Error del servidor (500)',
    501: 'Servicio no implementado (501)',
    502: 'Error de red (502)',
    503: 'Servicio no disponible (503)',
    504: 'Red agotada (504)',
    505: 'Versión HTTP no compatible (505)'
  }
}
