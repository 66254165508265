// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  index: {
    index_title: ' منصة إدارة المستودعات ',
    webtitle: 'WMS-ASG - نظام إدارة المستودعات مفتوح المصدر',
    home: 'الصفحة الأمامية',
    title: 'WMS-ASG',
    title_tip: 'الصفحة الرئيسية لـ WMS-ASG ',
    hide_menu: 'تصغير القائمة',
    api: 'وثائق API',
    translate: 'تغيير اللغة ',
    unread: 'رسائل غير مقروءة',
    login: 'تسجيل الدخول ',
    register: 'تسجيل',
    login_tip: 'أدخل OPENID واسم المستخدم الخاصين بك',
    register_tip: 'سجل كمسؤول ',
    logout: 'تسجيل خروج',
    user_login: 'تسجيل دخول المستخدم ',
    admin_login: 'دخول المشرف',
    return_to_login: 'العوده الي تسجيل الدخول',
    user_center: 'مركز المستعملين',
    change_user: 'تغيير المستخدم',
    view_my_openid: 'اعرض OPENID الخاص بي',
    your_openid: 'فتح الخاص بك ',
    contact_list: 'الاتصالات الأخيرة ',
    chat_more: 'المزيد من الأخبار التاريخية ',
    chat_no_more: 'لا مزيد من الأخبار',
    chat_send: 'إرسال',
    previous: 'الصفحة السابقة',
    next: 'الصفحة التالية',
    admin_name: 'مدير',
    password: 'كلمه السر',
    confirm_password: 'تأكيد كلمة المرور',
    staff_name: 'اسم االمستخدم',
    cancel: 'يلغي',
    close: 'إنهاء',
    submit: 'إرسال',
    download: 'تحميل',
    updatetitle: 'الترقية جاهزة',
    updatedesc: 'الإصدار جاهز للترقية',
    update: 'تحديث الان',
    chart: 'نقل',
    current_user: 'المستخدم الحالي'
  },
  menuItem: {
    dashboard: 'مركز التقارير',
    inbound: 'إدارة الإيصالات',
    outbound: 'إدارة الشحن',
    stock: 'ادارة المخزون',
    finance: 'المركز المالي',
    goods: 'إدارة السلع',
    baseinfo: 'الإعدادات الأساسية',
    warehouse: 'إعدادات المستودعات',
    staff: 'إدارةالمستخدم',
    driver: 'إدارة السائق',
    customerdn: 'طلب الزبون',
    supplierasn: 'طلب المورد',
    uploadcenter: 'مركز التحميل',
    downloadcenter: 'مركز التحميل'
  },
  contact: 'تواصل الآن',
  sendmessage: 'أرسل له رسالة',
  send: 'إرسال',
  nomoremessage: 'لا مزيد من الأخبار',
  loadmore: 'تحميل المزيد',
  new: 'الجديد',
  newtip: 'أضف قطعة من البيانات',
  refresh: 'تحديث',
  refreshtip: 'تحديث كافة البيانات',
  edit: 'تحرير هذه البيانات',
  confirmedit: 'تأكيد تحرير البيانات',
  canceledit: 'إلغاء تحرير البيانات',
  delete: 'احذف هذه البيانات',
  deletetip: 'هذه العملية لا رجوع فيها。',
  confirmdelete: 'تأكيد حذف البيانات',
  canceldelete: 'البيانات غير المحذوفة',
  download: 'تحميل',
  downloadtip: 'تنزيل كافة البيانات',
  frombin: 'من موقع المخزون',
  movetobin: 'تحويل',
  putaway: 'على الرف',
  cyclecount: 'جرد ديناميكي',
  cyclecountrecorder: 'سجل الجرد',
  search: 'بحث بكلمة مفتاحية',
  creater: 'مؤسس',
  createtime: 'وقت الابتكار',
  updatetime: 'تحديث الوقت',
  action: 'العمل',
  previous: 'الصفحة السابقة',
  next: 'الصفحة التالية',
  no_data: 'لا مزيد من البيانات',
  submit: 'تؤكد',
  cancel: 'يلغي',
  estimate: 'الشحن المقدر',
  downloadasnlist: 'قائمة التنزيل',
  downloadasndetail: 'تفاصيل التنزيل',
  downloadasnlisttip: 'قم بتنزيل قائمة إخطارات الوصول',
  downloadasndetailtip: 'تنزيل تفاصيل إشعار الوصول',
  printthisasn: 'عرض إشعار الوصول',
  confirmdelivery: 'تأكد من وصوله',
  finishloading: 'تأكد من اكتمال التفريغ',
  confirmsorted: 'تأكد من اكتمال الفرز',
  downloaddnlist: 'قائمة التنزيل',
  downloaddndetail: 'قائمة التنزيل',
  downloaddnlisttip: 'قم بتنزيل قائمة الفواتير',
  downloaddndetailtip: 'تحميل تفاصيل الفاتورة ',
  release: 'ترتيب المباراة',
  releaseallorder: 'إنشاء قائمة اختيار للجميع',
  releaseorder: 'إنشاء قائمة الاختيار',
  print: 'قائمة اختيار الطباعة',
  printthisdn: 'عرض وطباعة الفواتير',
  confirmorder: 'أكد الطلب',
  confirmpicked: 'تأكيد اكتمال الانتقاء',
  dispatch: 'التحميل والتسليم',
  deletebackorder: '  حذف الطلبات المتأخرة',
  confirminventoryresults: 'تأكيد نتيجة العد',
  baseinfo: {
    company_info: 'معلومات الشركة',
    supplier: 'المورد',
    customer: 'عميل',
    view_company: {
      company_name: 'اسم الشركة',
      company_city: 'مدينة',
      company_address: 'تبوك',
      company_contact: 'معلومات التواصل',
      company_manager: 'المالك',
      error1: 'الرجاء إدخال اسم الشركة',
      error2: 'الرجاء إدخال المدينة التي تقع فيها الشركة',
      error3: 'الرجاء إدخال عنوان الشركة',
      error4: 'الرجاء إدخال جهة اتصال الشركة',
      error5: 'الرجاء إدخال الشخص المسؤول عن الشركة '
    },
    view_supplier: {
      supplier_name: 'اسم المورد',
      supplier_city: 'مدينة',
      supplier_address: 'تبوك',
      supplier_contact: 'معلومات التواصل',
      supplier_manager: 'المالك',
      supplier_level: 'مستوى المورد',
      error1: 'الرجاء إدخال اسم المورد',
      error2: 'الرجاء إدخال المدينة التي يقع فيها المورد',
      error3: 'الرجاء إدخال عنوان المورد',
      error4: 'الرجاء إدخال معلومات الاتصال',
      error5: 'الرجاء إدخال الشخص المسؤول عن المورد',
      error6: 'الرجاء إدخال مستوى المورد'
    },
    view_customer: {
      customer_name: 'اسم العميل',
      customer_city: 'مدينة',
      customer_address: 'تبوك',
      customer_contact: 'معلومات التواصل',
      customer_manager: 'المالك',
      customer_level: 'مستوى العميل',
      error1: 'الرجاء إدخال اسم العميل',
      error2: 'الرجاء إدخال المدينة التي يوجد بها العميل',
      error3: 'الرجاء إدخال عنوان العميل',
      error4: 'الرجاء إدخال جهة اتصال',
      error5: 'الرجاء إدخال مدير الحساب',
      error6: 'الرجاء إدخال مستوى العميل'
    }
  },
  dashboards: {
    outbound_statements: 'تقرير الصادر',
    inbound_statements: 'تقرير استلام البضائع',
    inbound_and_outbound_statements: 'تقرير الاستلام والشحن',
    total_sales: 'إجمالي المبيعات',
    category_sales_ranking: 'تصنيف مبيعات الفئة',
    sales_volume_ranking: 'ترتيب المبيعات',
    sales_volumes_ranking: 'ترتيب حجم المبيعات',
    total_receipts: 'إجمالي الإيصالات',
    category_receipt_ranking: 'تصنيف تلقي التصنيف',
    receiving_quantity_ranking: 'ترتيب كمية الاستلام',
    Receiving_amount_ranking: 'ترتيب الإيصالات',
    view_tradelist: {
      mode_code: 'وسائل الصفقة',
      bin_name: 'اسم الموقع',
      goods_code: 'كود السلعة',
      goods_qty: 'كمية في اليد',
      creater: 'مؤسس',
      update_time: 'تحديث الوقت',
      create_time: 'وقت الابتكار',
      inbound: 'إيصال',
      outbound: 'سفينة '
    }
  },
  finance: {
    capital: 'أصول ثابتة',
    freight: 'إدارة الشحن',
    view_capital: {
      capital_name: 'اسم الأصول الثابتة',
      capital_qty: 'كمية',
      capital_cost: 'مقدار',
      error1: 'الرجاء إدخال اسم الأصل',
      error2: 'يجب أن يكون عدد الأصول أكبر من 0',
      error3: 'يجب أن يكون مبلغ الأصل أكبر من 0'
    },
    view_freight: {
      transportation_supplier: 'الناقل',
      send_city: 'مدينة المغادرة',
      receiver_city: 'مدينة الوصول',
      weight_fee: 'الشحن لكل كيلو',
      volume_fee: 'الشحن لكل متر مكعب',
      min_payment: 'الحد الأدنى من الشحن',
      error1: 'الرجاء إدخال الناقل',
      error2: 'الرجاء إدخال مدينة الإرسال',
      error3: 'الرجاء إدخال مدينة الاستلام',
      error4: 'يجب أن تكون تكلفة الشحن لكل كيلوغرام أكبر من 0',
      error5: 'يجب أن تكون تكلفة الشحن لكل متر مكعب أكبر من 0',
      error6: 'يجب أن يكون الحد الأدنى لرسوم الشحن أكبر من 0'
    }
  },
  driver: {
    driver: 'إدارة السائق',
    dispatchlist: 'سجل بيك اب',
    error1: 'الرجاء إدخال اسم السائق',
    error2: 'الرجاء إدخال رقم لوحة الترخيص',
    error3: 'الرجاء إدخال جهة اتصال',
    view_driver: {
      driver_name: 'اسم السائق',
      license_plate: 'لوحة الأرقام',
      contact: 'معلومات التواصل'
    },
    view_dispatch: {
      driver_name: 'اسم السائق',
      dn_code: 'رقم طلب الاستلام',
      contact: 'معلومات التواصل'
    }
  },
  upload_center: {
    initializeupload: 'بدء التحميل',
    uploadfiles: 'تحميل الملفات',
    upload: 'تحميل',
    uploadcustomerfile: 'تحميل قائمة العملاء',
    uploadgoodslistfile: 'تحميل قائمة نموذج المنتج',
    uploadsupplierfile: 'تحميل قائمة الموردين',
    downloadgoodstemplate: 'تنزيل نموذج المنتج',
    downloadcustomertemplate: 'تنزيل نموذج العميل',
    downloadsuppliertemplate: 'تنزيل نموذج المورد',
    addupload: 'إضافة تحميل'
  },
  download_center: {
    createTime: 'وقت الابتكار',
    reset: 'إعادة تعيين',
    start: 'يبدأ',
    end: 'ينهي'
  },
  community_mall: {
    communitymall: 'المجتمع مول'
  },
  goods: {
    goods_list: 'قائمة المنتجات',
    unit: 'وحدة السلع',
    class: 'فئة السلع',
    color: 'لون المنتج',
    brand: 'العلامة التجارية المنتج',
    shape: 'شكل المنتج',
    specs: 'مواصفات المنتج',
    origin: 'أصل سلعة',
    view_goodslist: {
      goods_code: 'كود السلعة',
      goods_desc: 'وصف المنتج',
      goods_name: 'اسم المنتج',
      goods_supplier: 'المورد',
      goods_weight: 'وزن المنتج (الوحدة: جرام)',
      goods_w: 'طول المنتج (الوحدة: مم)',
      goods_d: 'عرض المنتج (الوحدة: مم)',
      goods_h: 'ارتفاع المنتج (الوحدة: مم)',
      unit_volume: 'الحجم الأدنى للوحدة',
      goods_unit: 'وحدة السلع',
      goods_class: 'فئة السلع',
      goods_brand: 'العلامة التجارية المنتج',
      goods_color: 'لون المنتج',
      goods_shape: 'شكل المنتج',
      goods_specs: 'مواصفات المنتج',
      goods_origin: 'أصل سلعة',
      goods_cost: 'تكلفة البضائع',
      goods_price: 'سعر السلعة',
      print_goods_label: 'طباعة ملصقات المنتج',
      error1: 'الرجاء إدخال رمز المنتج',
      error2: 'الرجاء إدخال وصف المنتج',
      error3: 'الرجاء إدخال مورد العنصر',
      error4: 'يجب أن يكون وزن المنتج أكبر من 0',
      error5: 'يجب أن يكون طول المنتج أكبر من 0',
      error6: 'يجب أن يكون عرض المنتج أكبر من 0',
      error7: 'يجب أن يكون ارتفاع المنتج أكبر من 0',
      error8: 'الرجاء إدخال تكلفة البضائع',
      error9: 'الرجاء إدخال سعر المنتج'
    },
    view_unit: {
      goods_unit: 'وحدة السلع',
      error1: 'الرجاء إدخال وحدة المنتج'
    },
    view_class: {
      goods_class: 'فئة السلع',
      error1: 'الرجاء إدخال فئة المنتج'
    },
    view_color: {
      goods_color: 'لون المنتج',
      error1: 'الرجاء إدخال لون المنتج'
    },
    view_brand: {
      goods_brand: 'العلامة التجارية المنتج',
      error1: 'الرجاء إدخال ماركة المنتج'
    },
    view_shape: {
      goods_shape: 'شكل المنتج',
      error1: 'الرجاء إدخال شكل المنتج'
    },
    view_specs: {
      goods_specs: 'مواصفات المنتج',
      error1: 'الرجاء إدخال مواصفات المنتج'
    },
    view_origin: {
      goods_origin: 'أصل سلعة',
      error1: 'الرجاء إدخال بلد المنشأ'
    }
  },
  inbound: {
    asn: 'إشعار الوصول',
    predeliverystock: 'في انتظار الوصول',
    preloadstock: 'ليتم تفريغها',
    presortstock: 'ليتم فرزها',
    sortstock: 'مرتبة',
    shortage: 'نقص المعروض',
    more: 'المزيد من الوافدين',
    asnfinish: 'تفاصيل الإيصال',
    asndone: 'اكتمل الإيصال',
    view_sortstock: {
      error1: 'الرجاء إدخال كمية ، يجب أن تكون الكمية أكبر من 0'
    },
    view_asn: {
      asn_code: 'رقم إشعار الوصول',
      asn_status: 'حالة الإخطار بالوصول',
      goods_qty: 'عدد إخطارات الوصول',
      goods_actual_qty: 'كمية الوصول الفعلية',
      goods_shortage_qty: 'وصل بكمية قصيرة',
      goods_more_qty: 'المزيد من كمية الوصول',
      goods_damage_qty: 'عدد الوافدين المتضررين',
      presortstock: 'الكمية المراد فرزها',
      sorted_qty: 'كمية المخزون',
      total_weight: 'الوزن الإجمالي (الوحدة: كجم)',
      total_volume: 'الحجم الإجمالي (الوحدة: متر مكعب)'
    }
  },
  outbound: {
    dn: 'فاتورة',
    freshorder: 'قبل التسليم',
    neworder: 'فاتورة جديدة',
    backorder: 'متأخرات',
    pickstock: 'ويمكن الحصول عليها',
    pickedstock: 'منتقى',
    pickinglist: 'اختر تفاصيل القائمة',
    shippedstock: 'شحنها',
    received: 'تم إستقباله',
    pod: 'وقّع على الإيصال',
    view_dn: {
      dn_code: 'رقم الشحنة',
      dn_status: 'حالة الفاتورة',
      goods_qty: 'كمية الطلب',
      intransit_qty: 'الكمية التي تم شحنها',
      delivery_actual_qty: 'كمية الوصول الفعلية',
      delivery_shortage_qty: 'وصول قصير',
      delivery_more_qty: 'المزيد من الوافدين',
      delivery_damage_qty: 'وصول تالف',
      total_weight: 'الوزن الإجمالي (الوحدة: كجم)',
      total_volume: 'الحجم الإجمالي (الوحدة: متر مكعب)',
      customer: 'عميل'
    }
  },
  staff: {
    staff: 'قائمة موظف',
    check_code: 'رمز التحقق',
    view_staff: {
      staff_name: 'اسم الموظف',
      staff_type: 'نوع موظف',
      error1: 'الرجاء إدخال اسم الموظف',
      error2: 'الرجاء إدخال نوع الموظف',
      lock: 'قفل',
      unlock: 'الغاء القفل'
    }
  },
  stock: {
    stocklist: 'قائمة الأسهم',
    stockbinlist: 'قائمة الموقع',
    emptybin: 'مكان فارغ',
    occupiedbin: 'في موقع المخزون',
    view_stocklist: {
      goods_code: 'كود السلعة',
      goods_desc: 'وصف المنتج',
      goods_qty: 'المبلغ الكلي',
      onhand_stock: 'كمية في اليد',
      can_order_stock: 'الكمية التي يمكن طلبها',
      ordered_stock: 'الكمية المطلوبة',
      inspect_stock: 'كمية فحص الجودة',
      hold_stock: 'كمية مؤمنة',
      damage_stock: 'الكمية التالفة',
      asn_stock: 'عدد إخطارات الوصول',
      dn_stock: 'كمية الفاتورة',
      pre_load_stock: 'الكمية المراد تفريغها',
      pre_sort_stock: 'الكمية المراد فرزها',
      sorted_stock: 'الكمية المصنفة',
      pick_stock: 'الكمية المراد انتقاؤها',
      picked_stock: 'الكمية المختارة',
      back_order_stock: 'كمية البضائع المستحقة ',
      on_hand_inventory: 'المخزونات الحالية',
      history_inventory: 'الأسهم في ذلك الوقت',
      physical_inventory: 'كمية المخزون',
      difference: 'اختلاف',
      cyclecount: 'جرد ديناميكي',
      recyclecount: 'إعادة النظر',
      downloadcyclecount: 'قائمة الجرد',
      cyclecountresult: 'تحقق من النتائج',
      cyclecounttip: 'إنشاء جدول جرد ديناميكي',
      recyclecounttip: 'قم بإنشاء قائمة مرجعية',
      downloadcyclecounttip: 'قم بتنزيل صحيفة الجرد',
      cyclecountresulttip: 'تأكيد نتيجة العد',
      daychoice: 'اختيار التاريخ',
      daychoicetip: 'حدد قائمة التحقق للتاريخ المقابل',
      error1: 'يجب أن يكون العدد أكبر من 0',
      dateerror: 'تم تحديد تاريخ غير صحيح'
    }
  },
  warehouse: {
    warehouse: 'إعدادات المستودعات',
    binset: 'إعداد الموقع',
    binsize: 'حجم الموقع',
    property: ' ملكية الموقع',
    printbin: 'طباعة تسميات الموقع',
    view_warehouseset: {
      error1: 'الرجاء إدخال اسم المستودع',
      error2: 'الرجاء إدخال المدينة التي يقع فيها المستودع',
      error3: 'الرجاء إدخال عنوان المستودع',
      error4: 'الرجاء إدخال معلومات الاتصال بالمستودع',
      error5: 'الرجاء إدخال الشخص المسؤول عن المستودع'
    },
    view_warehouse: {
      warehouse_name: 'اسم المستودع',
      warehouse_city: 'مدينة',
      warehouse_address: 'تبوك',
      warehouse_contact: 'معلومات التواصل',
      warehouse_manager: 'المالك'
    },
    view_binset: {
      bin_name: 'اسم الموقع',
      bin_size: 'حجم الموقع',
      bin_property: 'ملكية الموقع',
      empty_label: 'معرف الموقع فارغ',
      error1: 'الرجاء إدخال اسم موقع',
      error2: 'الرجاء إدخال حجم الحاوية',
      error3: 'الرجاء إدخال سمات الموقع'
    },
    view_binsize: {
      bin_size: 'حجم الموقع',
      bin_size_w: 'طول مساحة التخزين (الوحدة: مم)',
      bin_size_d: 'عرض الموقع (الوحدة: مم)',
      bin_size_h: 'ارتفاع الموقع (الوحدة: مم)',
      error1: 'الرجاء إدخال حجم الحاوية',
      error2: 'يجب أن يكون طول الموقع أكبر من 0',
      error3: 'يجب أن يكون عرض الموقع أكبر من 0',
      error4: 'يجب أن يكون ارتفاع الموقع أكبر من 0'
    },
    view_property: {
      bin_property: 'ملكية الموقع'
    }
  },
  scan: {
    scan: 'يتم المسح',
    scan_asn: 'استعلام ASN',
    scan_dn: 'بحث DN',
    scan_sorting: 'فرز',
    scan_uptobin: 'على الرف',
    scan_picking: 'اختيار',
    scan_shipping: 'التحميل والتسليم',
    scan_movetobin: 'تحويل',
    scan_inventory: 'المخزون',
    scan_goodsquery: 'الاستعلام عن البضائع',
    scan_locationquery: 'الاستعلام عن الموقع',
    scan_goods_code: 'كود السلعة',
    scan_bin_name: 'اسم الموقع',
    scan_goods_label: 'ملصق المنتج',
    scan_goods_label_error: 'تسمية المنتج غير موجودة',
    view_binmove: {
      old_bin_name: 'اسم الموقع الأصلي',
      new_bin_name: 'اسم موقع جديد',
      qty: 'عدد المستودعات المنقولة',
      qty_error: 'يجب ألا تكون الكمية المراد نقلها أكبر من الكمية الحالية'
    },
    view_upToBin: {
      goods_actual_qty: 'كمية الوصول الفعلية',
      scan_qty: 'عدد عمليات المسح',
      scan_qty_error: 'لا يمكن أن يكون عدد عمليات الفحص أكبر من عدد مرات الوصول'
    },
    view_picking: {
      order_qty: 'كمية الطلب',
      picking_qty: 'اختر الكمية',
      picking_qty_error: 'يجب ألا تكون كمية الانتقاء أكبر من كمية الأمر'
    },
    view_shipping: {
      shipping_code: 'رقم الشحنة',
      driver_info: 'معلومات السائق',
      license_plate_number: 'لوحة الأرقام',
      name: 'اسم',
      contact_info: 'معلومات التواصل'
    }
  },
  notice: {
    valerror: 'الرجاء إدخال القيمة الصحيحة',
    unknow_error: 'خطأ غير معروف',
    network_error: 'شذوذ في الشبكة',
    nullerror: '',
    cyclecounterror: 'لايوجد بيانات',
    userererror: 'اسم المستخدم موجود بالفعل',
    capitalerror: 'اسم الأصل الثابت موجود بالفعل',
    valuenullerror: 'يرجى ملء البيانات كاملة',
    loginerror: 'الرجاء تسجيل الدخول أولا',
    goodserror: {
      goods_listerror: 'رمز المنتج موجود بالفعل ',
      goods_uniterror: ' السلع وحدة موجودة بالفعل ',
      goods_classerror: ' فئة السلع موجودة بالفعل',
      goods_colorerror: ' السلع اللون موجود بالفعل',
      goods_branderror: ' السلع العلامة التجارية موجودة بالفعل',
      goods_shapeerror: ' شكل المنتج موجود بالفعل',
      goods_specserror: ' مواصفات المنتج موجود بالفعل',
      goods_originerror: ' السلع المنتجة موجودة بالفعل'
    },
    baseinfoerror: {
      companyerror: 'اسم الشركة موجود بالفعل',
      customererror: ' اسم العميل موجود بالفعل',
      suppliererror: 'اسم المورد موجود بالفعل'
    },
    warehouseerror: {
      binseterror: ' اسم المكتبة موجود بالفعل',
      binsizeerror: 'حجم المكتبة موجودة بالفعل'
    },
    400: ' طلب خطأ(400)',
    401: 'عدم الحصول على إذن(401)',
    403: 'رفض الوصول(403)',
    404: 'الموارد غير موجودة (404)',
    405: 'تم تعطيل هذه الوظيفة(405)',
    408: 'طلب مهلة(408)',
    409: ' بيانات الصراع(409)',
    410: ' تم حذف البيانات (410)',
    500: 'خطأ في الخادم(500)',
    501: 'الخدمات غير المنفذة ( 501 )',
    502: 'خطأ في الشبكة(502)',
    503: ' الخدمات غير متوفرة ( 503 )',
    504: ' شبكة مهلة(504)',
    505: ' النسخة المتشعب غير معتمدة(505)'
  }
}
